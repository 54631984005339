import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import SectionTitle from "../SectionTitle";
import GridContainer from "../GridContainer";
import { breakpoints, colors } from "../../styles/variables";
import imgChar from "../../assets/images/media/ekko-with-flame.png";
import imgSwirl from "../../assets/images/media/bottom-swirl.png";

const Section = styled.section`
  position: relative;
  background-color: ${colors.purpleDark};
  padding-bottom: 11vw;

  .imageChar {
    width: 100%;
    max-width: 850px;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 1080px);
    transform: translateY(calc(-100% + 20px));
    pointer-events: none;
    z-index: 2;
    // used to fade the tail of the swirl as hard to position using z-index
    -webkit-mask: linear-gradient(transparent 15%, black 35%);
    mask: linear-gradient(transparent 15%, black 35%);
  }
  .swirlWrapper {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    overflow: hidden;
  }
  .imageSwirl {
    position: absolute;
    width: 100%;
    max-width: 500px;
    right: -50px;
    bottom: -300px;
  }
  .gridOut {
    height: 100%;
    position: relative;
  }
  .gridIn {
    grid-column: 4 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .buttonRow {
    text-align: center;
  }
  .text-header {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    .imageChar {
      width: 55%;
      max-width: 800px;
      top: 140px;
      left: -20%;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridIn {
      grid-column: 3 / span 4;
    }
    .imageSwirl {
      max-width: 350px;
      bottom: -225px;
    }
    .imageChar {
      top: calc(50% - 40px);
      left: -20%;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .gridOut {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .gridIn {
      grid-column: 1 / span 2;
    }
    .imageSwirl {
      max-width: 300px;
      bottom: -200px;
    }
    .imageChar {
      width: 70%;
      left: -27%;
    }
  }
  @media (max-width: 600px) {
    .imageSwirl {
      max-width: 150px;
      bottom: -75px;
      right: -40px;
    }
    .imageChar {
      top: 17%;
    }
  }
`;

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  top: -22vw;
  width: 100%;
  height: auto;
  z-index: 1;
  opacity: 0.3;
`;

const data = {
  title: "Media Inquiries",
  description:
    "In quis mi rhoncus, euismod neque non, tristique nisl. Maecenas porta dui in justo cursus, lacinia tincidunt odio auctor. Fusce quam velit, finibus at lorem vitae, ornare tempor nulla. ",
  button_text: "Contact us",
  click_url: "https://www.google.com",
};

export const fragment = graphql`
  fragment MediaContactFragment on Contentstack_convrgence_media_page {
    mediaContact: media_page_contact_section {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`;

export interface MediaInquiriesData {
  alignment: string;
  headline: string;
  bodyCopy: string;
  ctaLink: {
    title: string;
    href: string;
  };
}

interface Props {
  data: MediaInquiriesData;
}

const MediaInquiries = (props: Props) => {
  const { alignment, headline, bodyCopy, ctaLink } = props.data;

  return (
    <Section>
      <div className="swirlWrapper">
        <img className="imageSwirl" src={imgSwirl} />
      </div>
      <GridContainer className="gridOut">
        <img className="imageChar" src={imgChar} />
        <div className="gridIn">
          <SectionTitle colorTheme="white" title={headline} hideImageDivider={true} sectionType="media-inquiry">
            {bodyCopy}
          </SectionTitle>
          <div className="buttonRow">
            <ButtonText
              colorTheme="border-black"
              callback={() => {
                window.open(ctaLink.href, "_new");
              }}
            >
              {ctaLink.title}
            </ButtonText>
          </div>
        </div>
      </GridContainer>
      <SVGLightningLines
        fill="none"
        height="398"
        viewBox="0 0 1440 398"
        width="1440"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m2453.8 396.6-1622.8-236.6 16.5 90-1620.5-241" stroke="#1affce" />
        <path d="m2456.8 389.6-1619.501-236.6 16.5 90-1627.299-242" stroke="#1affce" />
      </SVGLightningLines>
    </Section>
  );
};

export default MediaInquiries;
